import {
  VERIFY_AUTH_REQUESTED,
  LOGOUT_REQUESTED,
  LOGIN_REQUESTED,
  UPDATE_AUTH
} from "./actionTypes"

export const updateAuth = (name, value) => ({
  type: UPDATE_AUTH,
  payload: {
    name,
    value
  }
})

export const verifyAuth = () => ({
  type: VERIFY_AUTH_REQUESTED
})

export const logOut = () => ({
  type: LOGOUT_REQUESTED
})

export const loginUser = (email, password) => ({
  type: LOGIN_REQUESTED,
  payload: { email, password }
})
