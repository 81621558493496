import React from "react"

import texts from "./texts"
import Night from "../components/common/NightIcon"

export const BACK = 1
export const RIGHT = 2
export const LEFT = 3
export const STRAIGHT_UP = 4
export const BELLY = 5
export const STRAIGHT_UP6 = 6
export const VIBRATION8 = 8
export const VIBRATION = 9

export const ROWS_PER_PAGE_OPTIONS = [15, 30, 50]

export const INITIAL_FILTERS = {
  sort: "name",
  order: "asc",
  page: 0,
  rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
  search: ""
}

export const tableTexts = {
  rowsPerPage: texts.rowsPerPage,
  toolbar: {
    search: texts.search,
    selected: texts.selected,
    delete: texts.delete,
    create: texts.create
  }
}

export const userTableHeadCells = [
  {
    name: "name",
    label: texts.name
  },
  {
    name: "age",
    label: texts.age
  },
  {
    name: "gender",
    label: texts.gender
  },
  {
    name: "backup",
    label: texts.backup,
    disableSort: true
  },
  {
    name: "share",
    label: texts.share,
    disableSort: true
  },
  {
    name: "symptoms",
    label: texts.symptoms
  },
  {
    name: "nights",
    label: texts.nights
  },
  {
    name: "lastImportedNight",
    label: texts.lastImported
  },
  {
    name: "firstNightOnRight",
    label: <Night label={texts.first} />
  },
  {
    name: "lastNightOnRight",
    label: <Night label={texts.last} />
  },
  {
    name: "csv",
    label: "CSV",
    disableSort: true
  }
]

export const positionsZeroValues = {left: 0, right: 0, back: 0, belly: 0, straightUp: 0, straightUp6: 0, vibration: 0}

export const chartZeroValues = {
  left: {hours: 0, percentage: 0, time: 0, positionTotal: 0},
  right: {hours: 0, percentage: 0, time: 0, positionTotal: 0},
  back: {hours: 0, percentage: 0, time: 0, positionTotal: 0},
  belly: {hours: 0, percentage: 0, time: 0, positionTotal: 0},
  straightUp: {hours: 0, percentage: 0, time: 0, positionTotal: 0},
  straightUp6: {hours: 0, percentage: 0, time: 0, positionTotal: 0},
  vibration: {hours: 0, percentage: 0, time: 0, positionTotal: 0},
}
