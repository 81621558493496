import { UPDATE_USER, SET_USER } from "./actionTypes"

const initialState = {
  id: null,
  name: null,
  nights: [],
  age: null,
  gender: null,
  isCloudEnabled: null,
  isShareDataEnabled: null,
  symptoms: null,
  isLoading: false
}

function reducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case UPDATE_USER: {
      const { name, value } = payload
      return {
        ...state,
        ...{ [name]: value }
      }
    }
    case SET_USER: {
      return {
        ...state,
        ...payload
      }
    }
    default:
      return state
  }
}

export default reducer
