import React, { useState, useEffect } from 'react'

import Typography from '@material-ui/core/Typography'

import BaseSelect from '../common/BaseSelect'
import texts from '../../constants/texts'
import { getMonthGraphData } from '../../utils/getMonthGraphData'
import BarChart from '../common/BarChart'

import styles from './styles.module.scss'

const MonthChart = ({ yearsData, nights, onBarPress }) => {
    const [yearsList, setYearsList] = useState([])
    const [monthList, setMonthList] = useState([])
    const [chartData, setChartData] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)
    const [selectedMonth, setSelectedMonth] = useState(null)

    useEffect(() => {
        const years = Object.keys(yearsData).map(el => ({value: el, label: el}))

        if(years.length > 0) {
            const year = years[years.length-1].value
            const months = Object.values(yearsData[year].months)
            const month = months[months.length-1].value

            setYearsList(years)
            setMonthList(months)
            setSelectedYear(year)
            setSelectedMonth(month)
            setChartData(getMonthGraphData(year, month, nights))
        }
    }, [yearsData, nights])

    const onYearChange = (evt) => {
        setSelectedYear(evt.target.value)
        setSelectedMonth('')
        setChartData(null)
    }

    const onMonthChange = (evt) => {
        setSelectedMonth(evt.target.value)
        setChartData(getMonthGraphData(selectedYear, evt.target.value, nights))
    }

    if (!selectedYear) return null

    return (
        <div className={styles.box}>
            <Typography variant="h6" gutterBottom>{texts.monthlyView}</Typography>
            <div className={styles.selectBox}>
                <div>
                    <BaseSelect
                        label={texts.year}
                        list={yearsList}
                        defaultValue={selectedYear}
                        onChange={onYearChange}
                    />
                </div>
                <div>
                    <BaseSelect
                        label={texts.month}
                        list={[{value: '', label: ''}, ...monthList]}
                        defaultValue={selectedMonth}
                        onChange={onMonthChange}
                    />
                </div>
            </div>
            <div>
                {
                    chartData && (
                        <BarChart
                            width={630}
                            height={300}
                            chartData={Object.values(chartData)}
                            onBarPress={onBarPress}
                            yTicks={[0,1,2,3,4,5,6,7,8]}
                            xInterval={0}
                            yInterval={0}
                            yDomain={[0, 8]}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default MonthChart
