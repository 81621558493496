import React from 'react'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'

const TrueFalse = ({ isTrue }) => (
    <div>
        {
            isTrue && <CheckIcon style={{ color:green[500]}} />
        }
        {
            !isTrue && <CloseIcon style={{color:red[500]}} />
        }
    </div>
)
  
export default TrueFalse