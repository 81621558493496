import React, { useState } from 'react'
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts'
import BarChartTooltip from '../BarChartTooltip'
import BarChartLegend from '../BarChartLegend'
import { chartLegendItems, chartInfo } from '../../../constants/chart'

const BarChartElement = ({
    chartData,
    xInterval,
    yInterval,
    width,
    height,
    yTicks,
    yDomain,
    onBarPress
}) => {
    const [selectedLegendKey, setSelectedLegendKey] = useState({})

    const onBarClick = ({ id }) => {
        onBarPress && onBarPress(id)
    }

    const onLegendPress = (key) => {
        setSelectedLegendKey({
            ...selectedLegendKey,
            [key]: !selectedLegendKey[key]
        })
    }
    // debugger

    return (
        <BarChart
            width={width || 250}
            height={height || 300}
            data={chartData}
            margin={{
                top: 35, right: 0, left: 0, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="name" interval={typeof xInterval !== undefined ? xInterval : 'preserveEnd'}/>
            <YAxis domain={yDomain || [0, 'auto']} ticks={yTicks} interval={typeof yInterval !== undefined ? yInterval : 'preserveEnd'} />
            <Tooltip content={<BarChartTooltip />} />
            <Legend content={
                <BarChartLegend selectedItems={selectedLegendKey} onItemPress={onLegendPress} items={chartLegendItems}/>
            } />
            {
                !selectedLegendKey.left && <Bar barSize={10} dataKey={chartInfo.left.key} stackId="a" fill={chartInfo.left.color} onClick={onBarClick} />
            }
            {
                !selectedLegendKey.right && <Bar dataKey={chartInfo.right.key} stackId="a" fill={chartInfo.right.color} onClick={onBarClick} />
            }
            {
                !selectedLegendKey.back && <Bar dataKey={chartInfo.back.key} stackId="a" fill={chartInfo.back.color} onClick={onBarClick} />
            }
            {
                !selectedLegendKey.belly && <Bar dataKey={chartInfo.belly.key} stackId="a" fill={chartInfo.belly.color} onClick={onBarClick} />
            }
            {
                !selectedLegendKey.straightUp && <Bar dataKey={chartInfo.straightUp.key} stackId="a" fill={chartInfo.straightUp.color} onClick={onBarClick} />
            }
            {
                !selectedLegendKey.straightUp6 && <Bar dataKey={chartInfo.straightUp6.key} stackId="a" fill={chartInfo.straightUp6.color} onClick={onBarClick} />
            }
        </BarChart>
    )
}

export default BarChartElement
