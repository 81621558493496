import React from 'react'

import {
    PieChart, Pie, Cell,
} from 'recharts'

const renderCustomizedLabel = ({ tooltipPosition: {x, y}, percent }) => {
    return (
        <>
        {
            percent === 0 ? null : (
                <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            )
        }
        </>
    )
}

const PieChartElement = ({ data }) => (
    <PieChart width={400} height={400}>
        <Pie
            data={data}
            isAnimationActive={false}
            fill="#8884d8"
            dataKey="value"
            labelLine={false}
            label={renderCustomizedLabel}
        >
            {
                data && data.map(item => <Cell key={item.name} fill={item.color} />)
            }
        </Pie>
  </PieChart>
)

export default PieChartElement
