import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"

import { useSelector, useDispatch } from 'react-redux'
import ImportToCSV from '@material-ui/icons/GetApp'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import AdvancedTable from '../../components/common/AdvancedTable'
import ExportToCsvModal from '../../components/ExportToCsvModal'
import { requestUsers, setFilters } from '../../store/users/actionCreators'
import { getUserList } from '../../store/users/selectors'

import { tableTexts, userTableHeadCells, ROWS_PER_PAGE_OPTIONS } from '../../constants/defaults'
import TrueFalse from '../../components/common/TrueFalse'
import { routes } from '../../constants/routes'

const Users = () => {
  const [opneCsvModal, setOpenCsvModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(requestUsers())
  }, [])

  const filters = useSelector(({ users }) => users.filters)
  const totalRows = useSelector(({ users }) => users.count)
  const isLoading = useSelector(({ users }) => users.isLoading)
  const list = useSelector(getUserList)

  const history = useHistory()
  
  const onRowPress = (data) => {
    history.push(routes.userCharts.replace(':id', data.id))
  }
  
  const onFiltersChange = data => {
    const isSearchChanged = data.search !== filters.search
    const isRowsPerPageChanged = data.rowsPerPage !== filters.rowsPerPage
    const resetPage = isSearchChanged || isRowsPerPageChanged

    dispatch(setFilters({ ...filters, ...data, page: resetPage ? 0 : data.page }))
  }

  const onImportToCvsPress = (user) => {
    setSelectedUser(user)
    setOpenCsvModal(true)
  }

  const renderCell = (key, data) => {
    if(key === 'csv') return (
      <IconButton onClick={(evt)=> {evt.stopPropagation(); onImportToCvsPress({...data})}}>
        <ImportToCSV />
      </IconButton>
    )
    if(key === 'backup') return (
      <TrueFalse isTrue={data.isCloudEnabled} />
    )
    if(key === 'share') return (
      <TrueFalse isTrue={data.isShareDataEnabled} />
    )

    if(key === 'nights') return data[key].length

    if(key === 'firstNightOnRight' || key === 'lastNightOnRight') return `${data[key]}%`

    return data[key]
  }

  return (
    <>
      <AdvancedTable
        headCells={userTableHeadCells}
        rows={list}
        rowsPerPage={filters.rowsPerPage}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        page={filters.page}
        texts={tableTexts}
        totalRows={totalRows}
        defaultFilters={filters}
        onRowPress={onRowPress}
        onFiltersChange={onFiltersChange}
        renderCell={renderCell}
      />
      {
        isLoading && <LinearProgress />
      }
      {
        selectedUser && (
          <ExportToCsvModal 
            isOpen={opneCsvModal} 
            user={selectedUser}
            onClose={() => setOpenCsvModal(false)}
          />
        )
      }
    </>
  )
}

export default Users