import React from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'


const Row = ({ headCells, renderCell, rowData, isSelected, enableCheckbox, onRowClick, onCheckClick }) => (
  <TableRow
    hover
    onClick={() => onRowClick(rowData)}
    role="checkbox"
    aria-checked={isSelected}
    selected={isSelected}
  >
    {enableCheckbox && (
      <TableCell padding="checkbox">
        <Checkbox
          checked={isSelected}
          onClick={(e) => { e.stopPropagation() }}
          onChange={() => onCheckClick(rowData.id)}
        />
      </TableCell>
    )}

    {
      headCells.map(({ name }, idx) => (
        <TableCell
          key={idx}
          component="td"
          scope="row">
          {
            typeof renderCell === 'function' ? renderCell(name, rowData) : rowData[name]
          }
        </TableCell>
      ))
    }
  </TableRow>
)

export default Row
