import moment from 'moment'

import { positionsZeroValues } from '../constants/defaults'
import { minutesToHours } from './time'
import {
    geSideValues,
    secondsToTime,
    getPositions,
    getPositionsPercentage,
    genSummaryArr
} from './importedNights'

const getWeekGraphData = (week, year, nights) => {
    const weekNights = {
      0: {name: 'S', ...positionsZeroValues},
      1: {name: 'M', ...positionsZeroValues},
      2: {name: 'T', ...positionsZeroValues},
      3: {name: 'W', ...positionsZeroValues},
      4: {name: 'T', ...positionsZeroValues},
      5: {name: 'F', ...positionsZeroValues},
      6: {name: 'S', ...positionsZeroValues},
    }

    nights.map(night => {
        const date = moment(night.dateStart)
        const nightWeek = date.week().toString()
        const nightYear = date.year().toString()

        if(nightWeek === week.toString() && nightYear === year.toString()) {
            const day = date.day()
            const sidesArr = night.data.toUint8Array()
            const values = geSideValues(sidesArr, night.checkInterval)

            weekNights[day] = {
                ...weekNights[day],
                left: values.left.hours,
                leftPerc: values.left.percentage,
                right: values.right.hours,
                rightPerc: values.right.percentage,
                back: values.back.hours,
                backPerc: values.back.percentage,
                belly: values.belly.hours,
                bellyPerc: values.belly.percentage,
                straightUp: values.straightUp.hours,
                straightUpPerc: values.straightUp.percentage,
                straightUp6: values.straightUp6.hours,
                straightUp6Perc: values.straightUp6.percentage
            }
            weekNights[day].id = night.id
            weekNights[day].date = night.dateStart
            weekNights[day].time = minutesToHours(night.duration)
        }

        return null
    })

    return weekNights
}

const getWeekSummary = (week, year, nights) => {
    const sum = {
        left: {positions: 0, seconds: 0},
        right: {positions: 0, seconds: 0},
        back: {positions: 0, seconds: 0},
        belly: {positions: 0, seconds: 0},
        straightUp: {positions: 0, seconds: 0},
        straightUp6: {positions: 0, seconds: 0},
    }

    nights.map(night => {
        const date = moment(night.dateStart)
        const nightWeek = date.week().toString()
        const nightYear = date.year().toString()

        if (nightWeek === week.toString() && nightYear === year.toString()) {
            const sidesArr = night.data.toUint8Array()
            const positions = getPositions(sidesArr)
            sum.left.seconds += positions.left * night.checkInterval
            sum.left.positions += positions.left
            sum.right.seconds += positions.right * night.checkInterval
            sum.right.positions += positions.right
            sum.back.seconds += positions.back * night.checkInterval
            sum.back.positions += positions.back
            sum.belly.seconds += positions.belly * night.checkInterval
            sum.belly.positions += positions.belly
            sum.straightUp.seconds += positions.straightUp * night.checkInterval
            sum.straightUp.positions += positions.straightUp
            sum.straightUp6.seconds += positions.straightUp6 * night.checkInterval
            sum.straightUp6.positions += positions.straightUp6
        }
    })

    const percentage = getPositionsPercentage({
        left: sum.left.positions,
        right: sum.right.positions,
        back: sum.back.positions,
        belly: sum.belly.positions,
        straightUp: sum.straightUp.positions,
        straightUp6: sum.straightUp6.positions,
    })

    sum.left.percentage = percentage.left
    sum.left.time = secondsToTime(sum.left.seconds)
    sum.right.percentage = percentage.right
    sum.right.time = secondsToTime(sum.right.seconds)
    sum.back.percentage = percentage.back
    sum.back.time = secondsToTime(sum.back.seconds)
    sum.belly.percentage = percentage.belly
    sum.belly.time = secondsToTime(sum.belly.seconds)
    sum.straightUp.percentage = percentage.straightUp
    sum.straightUp.time = secondsToTime(sum.straightUp.seconds)
    sum.straightUp6.percentage = percentage.straightUp6
    sum.straightUp6.time = secondsToTime(sum.straightUp6.seconds)

    return genSummaryArr(sum)
}

export {
    getWeekGraphData,
    getWeekSummary
}
