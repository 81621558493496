const chartInfo = {
    left: {
        color: '#78A640',
        key: 'left'
    },
    right: {
        color: '#CD605C',
        key: 'right'
    },
    back: {
        color: '#E0C36D',
        key: 'back'
    },
    belly: {
        color: '#D8D8D8',
        key: 'belly'
    },
    straightUp: {
        color: '#4B5B62',
        key: 'straightUp'
    },
    straightUp6: {
        color: '#B5D7E4',
        key: 'straightUp6'
    },
    vibration: {
        color: '#2A6318',
        key: 'vibration'
    }
}

const chartLegendItems = [
    { color: chartInfo.left.color, value: 'left', dataKey: chartInfo.left.key },
    { color: chartInfo.right.color, value: 'right', dataKey: chartInfo.right.key },
    { color: chartInfo.back.color, value: 'back', dataKey: chartInfo.back.key },
    { color: chartInfo.belly.color, value: 'belly', dataKey: chartInfo.belly.key },
    { color: chartInfo.straightUp.color, value: 'straightUp', dataKey: chartInfo.straightUp.key },
    { color: chartInfo.straightUp6.color, value: 'straightUp6', dataKey: chartInfo.straightUp6.key },
]

export {
    chartInfo,
    chartLegendItems
}
