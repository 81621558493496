import React from 'react'
import { Field, reduxForm } from 'redux-form'
import Button from '@material-ui/core/Button'

import TextField from '../../Fields/TextField'

import styles from './styles.module.scss'
import { required, email } from '../../../utils/validators'
import texts from '../../../constants/texts'

export const LOGIN_FORM = 'LOGIN_FORM'

const LoginForm = ({ handleSubmit, submitting }) =>  (
    <form onSubmit={ handleSubmit }>
        <Field name="email" type="text" component={TextField} label={texts.email} validate={[required, email]} />
        <Field name="password" type="password" component={TextField} label={texts.password} validate={[required]} />
        
        <div className={styles.btnBox}>
            <Button fullWidth variant="contained" component="button" type="submit" color="primary" disabled={submitting}>
                {texts.signIn}
            </Button>
        </div>
    </form>
)

export default reduxForm({
    form: LOGIN_FORM,
})(LoginForm)