import React from 'react'
import { ToastContainer } from 'react-toastify'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Header from '../common/Header'
import Menu from '..//common/Menu'

import './styles.scss'
import styles from './styles.module.scss'

const Layout = ({ children }) => (
  <>
    <CssBaseline />
    <Header />
    <div className={styles.root}>
      <Grid container spacing={3}>
        <Grid item xs={3} sm={2}>
          <Paper>
            <Menu />
          </Paper>
        </Grid>
        <Grid item xs={9} sm={10}>
          <Paper>{children}</Paper>
        </Grid>
      </Grid>
    </div>
    <ToastContainer autoClose={2000} hideProgressBar position="bottom-right" />
  </>
)

export default Layout
