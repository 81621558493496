export const genRowsWithSelection = (rows, isSelected) => {
  const allIds = []

  const byId = rows.reduce((acc, row) => {
    allIds.push(row.id)
    acc[row.id] = { ...row, isSelected }
    return acc
  }, {})

  return {
    byId,
    allIds
  }
}

export const getSelectedIds = arr =>
  arr.reduce((acc, elem) => {
    if (elem.isSelected) acc.push(elem.id)
    return acc
  }, [])
