import { UPDATE_USERS } from "./actionTypes"
import { INITIAL_FILTERS } from "../../constants/defaults"

const initialState = {
  count: 0,
  list: [],
  filters: INITIAL_FILTERS,
  // collectionCursor: null, // needs for firebase paginating
  isLoading: false
}

function reducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case UPDATE_USERS: {
      const { name, value } = payload
      return {
        ...state,
        ...{ [name]: value }
      }
    }
    default:
      return state
  }
}

export default reducer
