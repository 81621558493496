import { toast } from "react-toastify"
import moment from "moment"

import { takeLatest, put } from "redux-saga/effects"

import { USERS_REQUESTED } from "./actionTypes"
import { updateUsers } from "./actionCreators"

import { defaultDB } from "../firebase"
import texts from "../../constants/texts"
import { getNightsData } from "../../utils/importedNights"


function* getUsers() {
  try {
    yield put(updateUsers("isLoading", true))

    const querySnapshot = yield defaultDB.collection("records").get()

    const list = yield Promise.all(
      querySnapshot.docs.map(async doc => {
        const data = doc.data()
        const gender = data.gender === 0 ? texts.male : texts.female

        const nightsSnapshot = await defaultDB
          .collection(`records/${doc.id}/nights`)
          .get()
          
        const nightsData = nightsSnapshot.docs.reduce((res, night) => {
          const info = night.data()
          const hasStartDate = info.dateStart && info.dateStart.toDate

          if(hasStartDate) { // ignore night if no date
            const date = info.dateStart.toDate()
            const day = moment(date).format('MMDDYYYY')
            
            res[day] = { //avoid dublications
              ...info,
              dateStart: date,
              monthDay: day,
              id: night.id,
            }
          }

          return res
        }, {})

        const nights = Object.values(nightsData)
        const nightExtraData = getNightsData(nights) 

        return {
          ...data,
          gender,
          nights,
          ...nightExtraData,
          id: doc.id,
        }
      })
    )

    // console.log("users data", list)

    yield put(updateUsers("list", list))
    yield put(updateUsers("count", querySnapshot.size))
  } catch (error) {
    console.error(error)
    toast.error(`${error.message || texts.errorHappened}`)
  } finally {
    yield put(updateUsers("isLoading", false))
  }
}

export default function* watcher() {
  yield takeLatest(USERS_REQUESTED, getUsers)
}
