import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import BaseSelect from '../common/BaseSelect'
import texts from '../../constants/texts'

import styles from './styles.module.scss'
import { getWeekGraphData } from '../../utils/getWeekGraphData'

import BarChart from '../common/BarChart'

const WeekChart = ({ yearsData, nights, onBarPress }) => {
    const [yearsList, setYearsList] = useState([])
    const [weeksList, setWeeksList] = useState([])
    const [chartData, setChartData] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)
    const [selectedWeek, setSelectedWeek] = useState(null)

    useEffect(() => {
        const years = Object.keys(yearsData).map(el => ({value: el, label: el}))

        if(years.length > 0) {
            const year = years[years.length-1].value
            const weeks = Object.values(yearsData[year].weeks)
            const week = weeks[weeks.length-1].value

            setYearsList(years)
            setWeeksList(weeks)
            setSelectedYear(year)
            setSelectedWeek(week)
            setChartData(getWeekGraphData(week, year, nights))
        }
    }, [yearsData, nights])

    const onYearChange = (evt) => {
        setSelectedYear(evt.target.value)
        setSelectedWeek('')
        setChartData(null)
    }

    const onWeekChange = (evt) => {
        setSelectedWeek(evt.target.value)
        setChartData(getWeekGraphData(evt.target.value, selectedYear, nights))
    }

    if (!selectedYear) return null

    return (
        <div className={styles.box}>
            <Typography variant="h6" gutterBottom>{texts.weeklyView}</Typography>
            <div className={styles.selectBox}>
                <div>
                    <BaseSelect
                        label={texts.year}
                        list={yearsList}
                        defaultValue={selectedYear}
                        onChange={onYearChange}
                    />
                </div>
                <div>
                    <BaseSelect
                        label={texts.week}
                        list={[{value: '', label: ''}, ...weeksList]}
                        defaultValue={selectedWeek}
                        onChange={onWeekChange}
                    />
                </div>
            </div>
            <div>
                {
                    chartData && (
                        <BarChart
                            width={250}
                            height={300}
                            chartData={Object.values(chartData)}
                            onBarPress={onBarPress}
                            yTicks={[0,1,2,3,4,5,6,7,8]}
                            xInterval={0}
                            yInterval={0}
                            yDomain={[0, 8]}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default WeekChart
