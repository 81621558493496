import React from 'react'
import moment from 'moment'

const BarChartTooltip = ({active, payload}) => {
  if(active) {
    const time = payload[0].payload.time;
    const date = payload[0].payload.date;
      return (
        <div style={{background: 'rgba(255, 255, 255, 0.9)', padding: 10}}>
          {date && <div style={{fontSize: 11}}>{moment(date).format('DD MMM YYYY')}</div>}
          {time && <div><strong>{time}</strong></div>}
          {
            payload.map(({name, dataKey, color, payload}) => {
              const percent = payload[`${dataKey}Perc`] || 0
              return (
                <div key={name}>
                    <span style={{color}}>{name}</span> - {percent}%
                </div>
              )
            })
          }
        </div>
      )
  }

  return null
}

export default BarChartTooltip
