import React from 'react'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ExitIcon from '@material-ui/icons/PowerSettingsNew'

import mapStateToProps from './mapStateToProps'

import styles from './styles.module.scss'
import { logOut } from '../../../store/auth/actionCreators'

const Header = ({ title }) => {
  const dispatch = useDispatch()
  
  const onLogOutPress = () => {
    dispatch(logOut())  
  }

  return (
    <div className={styles.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={styles.title}>
            {title}
          </Typography>
          <IconButton color="inherit" onClick={onLogOutPress}>
            <ExitIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default connect(mapStateToProps)(Header)
