export default {
  rowsPerPage: "Rows per page",
  search: "Search",
  selected: "selected",
  delete: "Delete",
  create: "Create",
  name: "Name",
  age: "Age",
  gender: "Gender",
  male: "Male",
  female: "Female",
  symptoms: "Symptoms",
  cancel: "Cancel",
  exportSelected: "Export selected",
  exportAll: "Export all",
  selectExportDate: "Select export date",
  signIn: "Sign In",
  email: "Email",
  password: "Password",
  backup: "Backup",
  share: "Share",
  nothingToExport: "No data to export",
  nights: "Imported Nights",
  lastImported: "Last imported night",
  noDate: "No date",
  firstNightOnRight: "First night on right",
  lastNightOnRight: "Last night on right",
  last: "Last",
  first: "First",
  year: "Year",
  month: "Month",
  week: "Week",
  weeklyView: 'Weekly view',
  monthlyView: 'Monthly view',
  yearlyView: 'Yearly View',
  nightView: 'Night View',
  selectUser: 'Select User',
  noData: 'No Data',
  thisDay: 'This day',
  thisWeek: 'This week',
  thisMonth: 'This month',
  timeSpentInSleep: 'Time spent in each sleep possition',

  fieldIsRequired: "Field is reqiured",
  wrongEmail: "Wrong email provided",
  authNotAllowed: "Not allowed to Log In, check permissions",
  errorHappened: "Error happened, please try again later"
}
