import texts from "../constants/texts"

export const required = value =>
  value || typeof value === "number" ? undefined : texts.fieldIsRequired

export const email = value =>
  value &&
  !/^(([^<>()\[\]\.,:\s@\"]+(\.[^<>()\[\]\.,:\s@\"]+)*)|(\".+\"))@(([^<>()\.,\s@\"]+\.{0,1})+([^<>()\.,:\s@\"]{2,}|[\d\.]+))$/.test(
    value
  )
    ? texts.wrongEmail
    : undefined
