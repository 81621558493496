import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import 'react-dates/initialize'

import { DayPickerRangeController } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'


import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import texts from '../../constants/texts'
import { sortNights } from '../../utils/importedNights'

const nightsToStrArr = nights => nights.reduce((acc, {dateStart}) => {
  if(dateStart) acc.push(moment(dateStart).format('MMDDYY'))
  return acc
}, [])

const ExportToCsvModal = ({ isOpen, onClose, user }) => {
  const [focusedInput, setFocusedInput] = useState('startDate')
  const [nightDates, setNightDates] = useState([])
  const [selectedDates, setSelectedDates] = useState({startDate: null, endDate: null})
  
  useEffect(() => {
    setNightDates(nightsToStrArr(user.nights || []))
  }, [user])

  if(!user) return null

  const onDatesChange = ({startDate, endDate}) => {
    // set time and second to have posibility catch night date in between selected startDate and endDate
    startDate && startDate.hour(0)
    startDate && startDate.minute(0)
    startDate && startDate.second(0)
    endDate && endDate.hour(23)
    endDate && endDate.minute(59)
    endDate && endDate.second(59)

    setSelectedDates({startDate, endDate})
  }

  const onFocusChange = focused => {
    setFocusedInput(focused || 'startDate' )
  }

  const exportNights = (isSelectAll) => {
    const { nights } = user
    const { startDate, endDate } = selectedDates

    const zip = new JSZip()
    
    nights.map((night, index) => {
      const dateStart = moment(night.dateStart)

      const canAdd = isSelectAll ? night.dateStart : (night.dateStart && dateStart.isBetween(startDate, endDate, 'days', []))

      if(canAdd) {
        zip.file(`${dateStart.format('DD_MM_YYYY_hh_mm_ss')}#${index}.csv`, `${dateStart.format('DD,MM,YYYY,hh,mm,ss')},${night.duration},${night.data.toUint8Array().join(',')}`)
      }
      return null
    })

    setSelectedDates({ startDate: null, endDate: null })

    if(Object.keys(zip.files).length > 0) {
      zip.generateAsync({type:"blob"}).then(function (blob) { 
        saveAs(blob, 'imported-nights.zip')
      })
      onClose(false)
      return
    }

    toast.error(texts.nothingToExport)
  }

  const onExportSelectedPress = () => {
    exportNights(false)
  }

  const onExportAllPress = () => {
    exportNights(true)
  }

  const sortedNights = sortNights(user.nights)
  const visivleMonth = sortedNights.length > 0 ? moment(sortedNights[sortedNights.length - 1].dateStart) : moment()
  
  return (
    <>
      <Dialog onClose={onClose} open={isOpen} maxWidth="xl">
        <DialogTitle>{texts.selectExportDate}</DialogTitle>
        <DialogContent>
        <DayPickerRangeController
          startDate={selectedDates.startDate}
          endDate={selectedDates.endDate}
          onDatesChange={onDatesChange}
          focusedInput={focusedInput}
          onFocusChange={onFocusChange}
          minimumNights={0}
          numberOfMonths={3}
          initialVisibleMonth={() => visivleMonth}
          isDayHighlighted={(date) => {
            return nightDates.indexOf(date.format('MMDDYY')) !== -1
          }}
        />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>{texts.cancel}</Button>
          <Button 
            variant="contained" 
            onClick={onExportSelectedPress} 
            color="primary" 
            disabled={!selectedDates.startDate && !selectedDates.endDate}
          >
            {texts.exportSelected}
          </Button>
          <Button onClick={onExportAllPress} color="primary">{texts.exportAll}</Button>
        </DialogActions>
      </Dialog>  
    </>
  )
}

export default ExportToCsvModal
