import React from "react"
import { useSelector } from 'react-redux'

import { Route, Redirect } from "react-router-dom"
import { routes } from "../../constants/routes"
import Layout from '../Layout'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated)
  const isVerifying = useSelector(({ auth }) => auth.isVerifying)

  return (
    <Route
      {...rest}
      render={props => {
        if(isVerifying) return <div />
        if(isAuthenticated) return (
          <Layout>
              <Component {...props} />
          </Layout>
        )
        if(!isAuthenticated) return <Redirect to={routes.login} />
      }}
    />
  )
}
    
export default ProtectedRoute