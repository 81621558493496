import React from 'react'
import styles from './styles.module.scss'

const BarChartLegend = ({payload, onItemPress, items, selectedItems}) => {
    const onPress = (key) => {
        onItemPress && onItemPress(key)
    }
    const list = items || payload

    return (
        <ul className={styles.list}>
            {
                list.map(item => {
                    const styles = selectedItems[item.dataKey] ? {opacity: 0.4} : {}
                    return (
                        <li key={item.dataKey} onClick={() => onPress(item.dataKey)}>
                            <svg style={styles} width="14" height="14" viewBox="0 0 32 32" version="1.1">
                                <path stroke="none" fill={item.color} d="M0,4h32v24h-32z" />
                            </svg>
                            <span>{item.value}</span>
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default BarChartLegend
