import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"
import usersReducer from "./users/reducer"
import userReducer from "./user/reducer"
import authReducer from "./auth/reducer"

const rootReducer = combineReducers({
  form: formReducer,
  users: usersReducer,
  user: userReducer,
  auth: authReducer
})

export default rootReducer
