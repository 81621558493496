import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import PieChartElement from '../PieChart'

import { getDaySummarySidesData } from '../../utils/importedNights'
import { getWeekSummary } from '../../utils/getWeekGraphData'
import { getMonthSummary } from '../../utils/getMonthGraphData'
import texts from '../../constants/texts'

import styles from './styles.module.scss'

const SummaryCharts = ({ nights, selectedNight }) => {
    const [activeButton, setActiveButton] = useState('day')
    const [extraChartsData, setExtraChartsData] = useState(null)

    useEffect(() => {
        setSumData()
    }, [nights, selectedNight])

    const onPress = (type) => {
        setActiveButton(type)
        setSumData(type)
    }

    const setSumData = (typeVal) => {
        const {data, duration, checkInterval} = selectedNight || {}
        const type = typeVal || activeButton

        if(type === 'day') {
            setExtraChartsData(getDaySummarySidesData(data ? data.toUint8Array() : null, checkInterval, duration))
        }

        if(type === 'week') {
            const year = moment(selectedNight.dateStart).year()
            const week = moment(selectedNight.dateStart).week()
            setExtraChartsData(getWeekSummary(week, year, nights))
        }

        if(type === 'month') {
            const year = moment(selectedNight.dateStart).year()
            const month = moment(selectedNight.dateStart).month()
            setExtraChartsData(getMonthSummary(year, month, nights))
        }
    }

    if(!extraChartsData) return (
        <div>{texts.noData}</div>
    )

    return (
        <>
            <div>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                <Button
                    variant={activeButton === 'day' ? 'contained' : 'outlined'}
                    onClick={() => onPress('day')}
                >{texts.thisDay}</Button>
                <Button
                    variant={activeButton === 'week' ? 'contained' : 'outlined'}
                    onClick={() => onPress('week')}
                >{texts.thisWeek}</Button>
                <Button
                    variant={activeButton === 'month' ? 'contained' : 'outlined'}
                    onClick={() => onPress('month')}
                >{texts.thisMonth}</Button>
                </ButtonGroup>
            </div>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <PieChartElement data={extraChartsData} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ul className={styles.summaryChart}>
                    {
                        extraChartsData && extraChartsData.map(item => (
                            <li key={item.name}>
                                <div style={{minWidth: `${item.value + 5}%`, background: item.color}}>
                                {`${item.value}%`}
                                </div>
                                <span>
                                {item.name}
                                <strong>{item.time || ''}</strong>
                                </span>
                            </li>
                        ))
                    }
                    </ul>
                </Grid>
            </Grid>
        </>
    )
}

export default SummaryCharts
