import { UPDATE_AUTH } from "./actionTypes"

const initialState = {
  isLoggingIn: false,
  isLoggingOut: false,
  isVerifying: true,
  loginError: false,
  logoutError: false,
  isAuthenticated: false,
  user: {}
}

function reducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case UPDATE_AUTH: {
      const { name, value } = payload
      return {
        ...state,
        ...{ [name]: value }
      }
    }
    default:
      return state
  }
}

export default reducer
