import React, { useState, useEffect } from 'react'
import moment from 'moment'
import 'react-dates/initialize'

import { DayPickerSingleDateController } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'

const nightsToStrArr = nights => nights.reduce((acc, {dateStart}) => {
  if(dateStart) acc.push(moment(dateStart).format('MMDDYY'))
  return acc
}, [])

const ChooseNightDate = ({ selectedNight, nights, onNightSelect }) => {
  const [nightDates, setNightDates] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  
  useEffect(() => {
    nights && setNightDates(nightsToStrArr(nights || []))
  }, [nights])

  useEffect(() => {
    selectedNight && setSelectedDate(moment(selectedNight.dateStart))
  }, [selectedNight])

  const onDateChange = (date) => {
    setSelectedDate(date)
    const night = nights.find(item => moment(item.dateStart).format('DDMMYYYY') === date.format('DDMMYYYY'))
    onNightSelect(night ? night.id: null)
  }

  const visibleMonth = nights.length > 0 ? moment(nights[nights.length - 1].dateStart) : moment()
    
  return (
        <DayPickerSingleDateController
          date={selectedDate}
          onDateChange={onDateChange}
          initialVisibleMonth={() => visibleMonth}
          numberOfMonths={1}
          isOutsideRange={() => false}
          isDayHighlighted={(date) => {
            return nightDates.indexOf(date.format('MMDDYY')) !== -1
          }}
    />
  )    
}

export default ChooseNightDate
