import { USER_REQUESTED, UPDATE_USER, SET_USER } from "./actionTypes"

export const serUser = data => ({
  type: SET_USER,
  payload: data
})

export const updateUser = (name, value) => ({
  type: UPDATE_USER,
  payload: {
    name,
    value
  }
})

export const requestUser = id => ({
  type: USER_REQUESTED,
  payload: id
})
