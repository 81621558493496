import React from 'react'
import { useHistory } from "react-router-dom"

import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Typography from '@material-ui/core/Typography'

import { routes } from '../../../constants/routes'

const Menu = () => {
  const history = useHistory()

  return (
    <MenuList>
      {/*<MenuItem selected={false} onClick={() => {history.push(routes.dashboard)}}>*/}
      {/*  <Typography variant="subtitle1">Dashboard</Typography>*/}
      {/*</MenuItem>*/}
      <MenuItem onClick={() => { history.push(routes.users) }}>
        <Typography variant="subtitle1">Users</Typography>
      </MenuItem>
    </MenuList>
  )
}

export default Menu
