import firebase from "firebase"

const firebaseConfig = {
  apiKey: "AIzaSyCxT7PDa1L5XQaappptU8s_-0KEkVcTFuk",
  authDomain: "left-ios-beta.firebaseapp.com",
  databaseURL: "https://left-ios-beta.firebaseio.com",
  projectId: "left-ios-beta",
  storageBucket: "left-ios-beta.appspot.com",
  messagingSenderId: "741065787168",
  appId: "1:741065787168:web:0c8c4cd852696af9c97945",
  measurementId: "G-4E5ENXSVTX"
}

firebase.initializeApp(firebaseConfig)

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
})

firebase.firestore().enablePersistence({
  synchronizeTabs: true
})

const defaultDB = firebase.firestore()


// defaultDB.ref().collection('records')
//     .orderBy('name')
//     .startAt('Ole')
//     .endAt(`${'Ole'}\uf8ff`)
//     .limit(5).get().then((snap) => {
//       debugger
//     })

export { defaultDB }
