import { all, fork } from "redux-saga/effects"

import usersSagas from "./users/sagas"
import userSagas from "./user/sagas"
import authSagas from "./auth/sagas"

const sagas = [userSagas, usersSagas, authSagas]

export default function* rootSaga() {
  yield all(sagas.map(saga => fork(saga)))
}
