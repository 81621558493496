import React, { useState, useEffect } from 'react'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TablePagination from '@material-ui/core/TablePagination'

import HeaderRow from './HeadRow'
import Rows from './Rows'
import ToolBar from './ToolBar'

import { genRowsWithSelection, getSelectedIds } from './utils'

const AdminTable = ({
  headCells, rows, rowsPerPage, rowsPerPageOptions, page, totalRows, texts, defaultFilters, enableCheckboxes, debounceTimeout,
  onFiltersChange, onDeletePress, onRowPress, onCreatePress, customFilters, renderCell, showSearch,
}) => {
  const [rowsData, toggleSelectedRow] = useState(genRowsWithSelection(rows, false))
  const [filters, setFilters] = useState(defaultFilters)

  useEffect(
    () => {
      toggleSelectedRow(genRowsWithSelection(rows, false))
    },
    [rows],
  )

  const onSelectRow = (id) => (
    toggleSelectedRow({
      ...rowsData,
      byId: {
        ...rowsData.byId,
        [id]: {
          ...rowsData.byId[id],
          isSelected: !rowsData.byId[id].isSelected,
        },
      },
    })
  )

  const onSelectAll = (isSelectedAll) => (
    toggleSelectedRow({
      ...rowsData,
      ...genRowsWithSelection(rows, isSelectedAll),
    })
  )

  const updateFilters = (obj) => {
    
    const data = {
      ...filters,
      ...obj,
    }

    setFilters(data)

    if (typeof onFiltersChange === 'function') {
      onFiltersChange(data)
    }
  }

  const onSortPress = (sortBy) => {
    const isDesc = filters.sort === sortBy && filters.order === 'desc'
    
    updateFilters({
      sort: sortBy,
      order: isDesc ? 'asc' : 'desc',
    })
  }

  const onSearchChange = (search) => updateFilters({ search })

  const onPageChange = (page) => updateFilters({ page })

  const onRowsPerPageChange = ({ target: { value } }) => (
    updateFilters({ rowsPerPage: parseInt(value, 10) })
  )

  const getNumOfSelectedRows = () => {
    const arr = Object.values(rowsData.byId) || []
    return arr.filter((row) => row.isSelected).length
  }

  const onDeleteBtnPress = () => {
    if (typeof onDeletePress === 'function') {
      onDeletePress(getSelectedIds(Object.values(rowsData.byId) || []))
    }
  }

  const getIsIndeterminateChecked = () => {
    const arr = Object.values(rowsData.byId)
    const selectedRowsSize = arr.filter(({ isSelected }) => !!isSelected).length
    return selectedRowsSize > 0 && selectedRowsSize < arr.length
  }

  const getIsCheckedAll = () => {
    const arr = Object.values(rowsData.byId)
    return (arr.length > 0 && arr.every(({ isSelected }) => !!isSelected))
  }

  return (
    <>
      <ToolBar
        numSelected={getNumOfSelectedRows()}
        texts={texts.toolbar}
        debounceTimeout={debounceTimeout}
        defaultSearchQuery={filters.search}
        showSearch={showSearch}
        onCreatePress={onCreatePress}
        onDeletePress={onDeleteBtnPress}
        onSearchChange={onSearchChange}
        customFilters={customFilters}
      />
      <Table>
        <HeaderRow
          cellList={headCells}
          onSelectAllPress={onSelectAll}
          isIndeterminateChecked={getIsIndeterminateChecked()}
          isAllChecked={getIsCheckedAll()}
          onSortPress={onSortPress}
          activeSortKey={filters.sort}
          order={(filters.order)}
          enableCheckbox={enableCheckboxes}
        />
        <TableBody>
          <Rows
            rowsData={rowsData}
            headCells={headCells}
            enableCheckboxes={enableCheckboxes}
            onRowSelect={onSelectRow}
            onRowPress={onRowPress}
            renderCell={renderCell}
          />
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={texts.rowsPerPage}
        onChangePage={(evt, page) => { onPageChange(page) }}
        onChangeRowsPerPage={onRowsPerPageChange}
      />
    </>
  )
}

export default AdminTable
