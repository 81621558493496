import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams} from "react-router-dom"

import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import LinearProgress from '@material-ui/core/LinearProgress'

import { requestUser } from '../../store/user/actionCreators'
import { requestUsers } from '../../store/users/actionCreators'
import { getNightsData } from '../../store/user/selectors'

import WeekChart from '../../components/WeekChart'
import MonthChart from '../../components/MonthChart'
import YearChart from '../../components/YearChart'
import NightChart from '../../components/NightChart'
import { getSimpleUsersList } from '../../store/users/selectors'
import { routes } from '../../constants/routes'

import styles from './styles.module.scss'
import texts from '../../constants/texts'
import ChooseNightDate from '../../components/common/ChooseNightDate'
import { sortNights } from '../../utils/importedNights'

import SummaryCharts from '../../components/SummaryCharts'

const UserCharts = () => {
  const [activeTab, setActiveTab] = useState(1)
  const [selectedNight, setSelectedNight] = useState(null)
  const [nights, setNights] = useState(null)

  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const users = useSelector(({ users }) => users.list)
  const isLoading = useSelector(({ user }) => user.isLoading)
  const user = useSelector(({ user }) => user)
  const { years } = useSelector(getNightsData)
  const searchUserList = useSelector(getSimpleUsersList)

  useEffect(() => {
    dispatch(requestUser(params.id))

    if(users.length === 0) {
      dispatch(requestUsers())
    }
  }, [])

  useEffect(() => {
    const list = sortNights(user.nights)
    setNights(sortNights(list))
    setSelectedNight(list[list.length - 1] || null)
  }, [user])

  const handleTabsChange = (event, tab) => {
    setActiveTab(tab)
  }

  const onBarPress = (id) => {
    setSelectedNight(id ? nights.find(night => night.id === id): null)
  }

  const onSelectUser = (evt, val) => {
    if(val && val.id) {
      dispatch(requestUser(val.id))
      setNights(null)
      setSelectedNight(null)
      history.push(routes.userCharts.replace(':id', val.id))
    }
  }

  if(isLoading) return <LinearProgress />
  if(!user.id) return null

  return (
    <div className={styles.root}>
      <div className={styles.selectUser}>
        <Autocomplete
            options={searchUserList}
            getOptionLabel={option => option.title}
            style={{ width: 300 }}
            renderInput={params => <TextField {...params} label={texts.selectUser} />}
            onChange={onSelectUser}
            filterSelectedOptions
            defaultValue={{id: user.id, title: user.name}}
        />
      </div>
      {
        nights && (
          <>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabsChange}
            >
              <Tab label="Week/Month" value={1} />
              <Tab label="Year" value={2}/>
            </Tabs>

            {
              activeTab === 1 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <WeekChart yearsData={years} nights={nights} onBarPress={onBarPress}/>
                  </Grid>
                  <Grid item xs={12} md={8} lg={9}>
                    <MonthChart yearsData={years} nights={nights} onBarPress={onBarPress}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider /><br/>
                    <Typography variant="h6" gutterBottom>{texts.nightView}</Typography>
                    <div className={styles.nightChartAndDate}>
                      <div><NightChart night={selectedNight} /></div>
                      <div className={styles.calendar}><ChooseNightDate nights={nights} selectedNight={selectedNight} onNightSelect={onBarPress} /></div>
                    </div>
                  </Grid>
                  <Grid item xs={12}  >
                    <Divider />
                    <br/>
                    <Typography variant="h6" gutterBottom>{texts.timeSpentInSleep}</Typography>
                    <SummaryCharts selectedNight={selectedNight} nights={nights}/>
                  </Grid>
                </Grid>
              )
            }
            {
              activeTab === 2 && (
                <YearChart yearsData={years} nights={nights} />
              )
            }
          </>
        )
      }
    </div>
  )
}

export default UserCharts
