import React, { useEffect } from 'react'

import classNames from 'classnames'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import debounce from 'debounce'
import { Button } from '@material-ui/core'

import useStyles from './styles'

const DEBOUNCE_TIME_OUT = 1000

const ToolBar = ({ numSelected, texts = {}, defaultSearchQuery, customFilters, onDeletePress, debounceTimeout = DEBOUNCE_TIME_OUT, onCreatePress, onSearchChange, showSearch = true }) => {
    const classes = useStyles({})

    const onChangeSearch = debounce(
        (value) => {
            if (typeof onSearchChange === 'function') {
                onSearchChange(value)
            }
        },
        debounceTimeout,
    )

    useEffect(
        () => {
            onChangeSearch('')
        },
        [showSearch],
    )

    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {
                    numSelected > 0
                        ? (
                            <Typography color="inherit" variant="subtitle1">
                                {numSelected} {texts.selected}
                            </Typography>
                        )
                        : (
                            <>
                                {
                                    typeof onSearchChange === 'function' && showSearch && (
                                        <Input
                                            type="text"
                                            defaultValue={defaultSearchQuery}
                                            onChange={(e) => { onChangeSearch(e.target.value) }}
                                            placeholder={texts.search}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            }
                                        />
                                    )
                                }
                                {customFilters}
                            </>
                        )
                }
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {
                    numSelected > 0
                        ? (
                            <Tooltip title={texts.delete}>
                                <IconButton onClick={onDeletePress}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )
                        : (
                            typeof onCreatePress === 'function' && (
                                <Tooltip title={texts.create}>
                                    <Button size="medium" color="primary" onClick={onCreatePress}>
                                        <AddIcon className={classes.addIcon} />
                                        {texts.create}
                                    </Button>
                                </Tooltip>
                            )
                        )
                }
            </div>
        </Toolbar>

    )
}

export default ToolBar
