import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Dashboard from "./pages/Dashboard"
import Users from "./pages/Users"
import UserCharts from "./pages/UserCharts"
import Login from "./pages/Login"

import { routes } from "./constants/routes"
import ProtectedRoute from "./components/ProtectedRoute"

const App = () => (
  <Router>
    <Switch>
      {/*<ProtectedRoute exact path={routes.dashboard} component={Dashboard} />*/}
      <ProtectedRoute exact path={routes.users} component={Users} />
      <ProtectedRoute exact path={routes.userCharts} component={UserCharts} />
      <Route exact path={routes.login} component={Login} />
    </Switch>
  </Router>
)

export default App
