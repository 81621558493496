import { toast } from "react-toastify"
import firebase from "firebase"

import { takeLatest, put, call } from "redux-saga/effects"

import {
  VERIFY_AUTH_REQUESTED,
  LOGOUT_REQUESTED,
  LOGIN_REQUESTED
} from "./actionTypes"
import texts from "../../constants/texts"

import { defaultDB } from "../firebase"
import { updateAuth } from "./actionCreators"

function getCurrentUser(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

function* logOut() {
  try {
    yield firebase.auth().signOut()
    yield put(updateAuth("isAuthenticated", false))
  } catch (error) {
    toast.error(error.message)
    yield put(updateAuth("isVerifying", false))
    yield put(updateAuth("isAuthenticated", false))
  }
}

function* verifyAuth() {
  try {
    yield put(updateAuth("isVerifying", true))

    const user = yield getCurrentUser(firebase.auth())

    if (user) {
      const userDoc = yield defaultDB
        .collection("records")
        .doc(user.uid)
        .get()
      const data = userDoc.data()

      if (data && data.admin) {
        yield put(updateAuth("isAuthenticated", true))
        yield put(updateAuth("isVerifying", false))
      } else {
        yield put(updateAuth("isAuthenticated", false))
        yield put(updateAuth("isVerifying", false))
      }
    } else {
      yield put(updateAuth("isAuthenticated", false))
      yield put(updateAuth("isVerifying", false))
    }
  } catch (error) {
    toast.error(error.message)
    yield put(updateAuth("isAuthenticated", false))
    yield put(updateAuth("isVerifying", false))
    // yield call(logOut)
  }
}

function* logIn({ payload: { email, password } }) {
  try {
    const { user } = yield firebase
      .auth()
      .signInWithEmailAndPassword(email, password)

    const userDoc = yield defaultDB
      .collection("records")
      .doc(user.uid)
      .get()
    const data = userDoc.data()

    if (data && data.admin) {
      yield put(updateAuth("isVerifying", false))
      yield put(updateAuth("isAuthenticated", true))
    } else {
      throw new Error(texts.authNotAllowed)
    }
  } catch (error) {
    toast.error(error.message)
    yield call(logOut)
    yield put(updateAuth("isVerifying", false))
    yield put(updateAuth("isAuthenticated", false))
  }
}

export default function* watcher() {
  yield takeLatest(VERIFY_AUTH_REQUESTED, verifyAuth)
  yield takeLatest(LOGIN_REQUESTED, logIn)
  yield takeLatest(LOGOUT_REQUESTED, logOut)
}
