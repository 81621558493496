import moment from "moment"

import texts from "../constants/texts"
import {
  RIGHT,
  BACK,
  LEFT,
  STRAIGHT_UP,
  STRAIGHT_UP6,
  BELLY, VIBRATION,
  positionsZeroValues,
} from "../constants/defaults"
import { chartInfo } from "../constants/chart"

const sortNights = nights => nights.sort(
  (a, b) => moment(a.dateStart).unix() - moment(b.dateStart).unix()
)

const getLastImportedDate = sortedNights =>
  sortedNights.length > 0
    ? moment(sortedNights[sortedNights.length - 1].dateStart).format(
        "DD/MM/YYYY"
      )
    : texts.noDate

const cleanNightRecords = arr => arr.filter(
  el =>
    el === RIGHT ||
    el === BACK ||
    el === LEFT ||
    el === STRAIGHT_UP ||
    el === STRAIGHT_UP6 ||
    el === BELLY
)

const getSleepOnRight = arr => {
  const cleanedArr = cleanNightRecords(arr)
  const onRight = cleanedArr.filter(el => el === RIGHT)
  if (cleanedArr.length === 0 || onRight.length === 0) return 0
  return Math.round((onRight.length / cleanedArr.length) * 100)
}

const getNightsData = nights => {
  const sortedNights = sortNights(nights)
  const first = sortedNights[0] ? sortedNights[0].data.toUint8Array() : []
  const last = sortedNights[sortedNights.length - 1]
    ? sortedNights[sortedNights.length - 1].data.toUint8Array()
    : []

  return {
    firstNightOnRight: getSleepOnRight(first),
    lastNightOnRight: getSleepOnRight(last),
    lastImportedNight: getLastImportedDate(sortedNights)
  }
}

const getPositions = (arr) => {
  const sideTottals = arr.reduce((res, val) => {
      if(val === LEFT) res.left++
      if(val === RIGHT) res.right++
      if(val === BACK) res.back++
      if(val === BELLY) res.belly++
      if(val === STRAIGHT_UP) res.straightUp++
      if(val === STRAIGHT_UP6) res.straightUp6++
      if(val === VIBRATION) res.vibration++
      return res
  }, {...positionsZeroValues})

  return {...sideTottals}
}

const secondsToTime = (sec) => {
    const hours = Math.floor(sec / 3600);
    const totalSeconds = sec % 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`
}

const geSideValues = (signalsArr, checkInterval) => {
    const positions = getPositions(signalsArr)
    const percentages = getPositionsPercentage(positions)
    return {
        left: {
              hours: positions.left * checkInterval / 60 / 60,
              positionTotal: positions.left,
              percentage: percentages.left,
              seconds: positions.left * checkInterval
        },
        right: {
            hours: positions.right * checkInterval / 60 / 60,
            positionTotal: positions.right,
            percentage: percentages.right,
            seconds: positions.right * checkInterval
        },
        back: {
            hours: positions.back * checkInterval / 60 / 60,
            positionTotal: positions.back,
            percentage: percentages.back,
            seconds: positions.back * checkInterval
        },
        belly: {
            hours: positions.belly * checkInterval / 60 / 60,
            positionTotal: positions.belly,
            percentage: percentages.belly,
            seconds: positions.belly * checkInterval
        },
        straightUp: {
            hours: positions.straightUp * checkInterval / 60 / 60,
            positionTotal: positions.straightUp,
            percentage: percentages.straightUp,
            seconds: positions.straightUp * checkInterval
        },
        straightUp6: {
            hours: positions.straightUp6 * checkInterval / 60 / 60,
            positionTotal: positions.straightUp6,
            percentage: percentages.straightUp6,
            seconds: positions.straightUp6 * checkInterval
        }
    }
}

const getPositionsPercentage = (positions) => {
    const { left, right, back, belly, straightUp, straightUp6 } = positions
    const total = left + right + back + belly + straightUp + straightUp6

    let percLeft = Math.round(left / total * 100)
    let percRight = Math.round(right / total * 100)
    let percBack = Math.round(back / total * 100)
    let percBelly = Math.round(belly / total * 100)
    let percStraightUp = Math.round(straightUp / total * 100)
    let percStraightUp6 = Math.round(straightUp6 / total * 100)

    // make sure that sum of percentages equal 100
    const sum = percLeft + percRight + percBack + percBelly + percStraightUp + percStraightUp6

    const adjust = 100 - sum;
    if (adjust !== 0) {
        const maxPerc = Math.max(percLeft, percRight, percBack, percBelly, percStraightUp, percStraightUp6)
        if(percBack === maxPerc) {
            percBack += adjust
        } else if(percLeft === maxPerc) {
            percLeft += adjust
        } else if(percBelly === maxPerc) {
            percBelly += adjust
        } else if(percStraightUp === maxPerc) {
            percStraightUp += adjust
        } else if(percStraightUp6 === maxPerc) {
            percStraightUp6 += adjust
        } else {
            percRight += adjust
        }
    }

    return {
        left: percLeft,
        right: percRight,
        back: percBack,
        belly: percBelly,
        straightUp: percStraightUp,
        straightUp6: percStraightUp6
    }
}

const genSummaryArr = (data) => [
  {name: 'left', value: data.left.percentage, color: chartInfo.left.color, time: data.left.time},
  {name: 'right', value: data.right.percentage, color: chartInfo.right.color, time: data.right.time},
  {name: 'back', value: data.back.percentage, color: chartInfo.back.color, time: data.back.time},
  {name: 'belly', value: data.belly.percentage, color: chartInfo.belly.color, time: data.belly.time},
  {name: 'straightUp', value: data.straightUp.percentage, color: chartInfo.straightUp.color, time: data.straightUp.time},
  {name: 'straightUp6', value: data.straightUp6.percentage, color: chartInfo.straightUp6.color, time: data.straightUp6.time},
]

const getDaySummarySidesData = (signalsArr, checkInterval) => {
    if(!signalsArr) return null
    const values = geSideValues(signalsArr, checkInterval)
    values.left.time = secondsToTime(values.left.seconds)
    values.right.time = secondsToTime(values.right.seconds)
    values.back.time = secondsToTime(values.back.seconds)
    values.belly.time = secondsToTime(values.belly.seconds)
    values.straightUp.time = secondsToTime(values.straightUp.seconds)
    values.straightUp6.time = secondsToTime(values.straightUp6.seconds)
    return [...genSummaryArr(values)]
}

export {
  getNightsData,
  sortNights,
  cleanNightRecords,
  geSideValues,
  getDaySummarySidesData,
  genSummaryArr,
  getPositionsPercentage,
  getPositions,
  secondsToTime
}
