import { toast } from "react-toastify"
import moment from "moment"
import { takeLatest, put } from "redux-saga/effects"

import { USER_REQUESTED } from "./actionTypes"
import { updateUser, serUser } from "./actionCreators"

import { defaultDB } from "../firebase"
import texts from "../../constants/texts"

function* getUsers({ payload }) {
  try {
    yield put(updateUser("isLoading", true))

    const querySnapshot = yield defaultDB
      .collection("records/")
      .doc(payload)
      .get()

    const nightsSnapshot = yield defaultDB
      .collection(`records/${payload}/nights`)
      .get()

    const nightsData = nightsSnapshot.docs.reduce((res, night) => {
      const info = night.data()
      const hasStartDate = info.dateStart && info.dateStart.toDate

      if(hasStartDate) { // ignore night if no date
        const date = info.dateStart.toDate()
        const day = moment(date).format('MMDDYYYY')
        
        res[day] = { //avoid dublications
          ...info,
          dateStart: date,
          monthDay: day,
          id: night.id,
        }
      }

      return res
    }, {})

    const nights = Object.values(nightsData)

    yield put(
      serUser({
        id: querySnapshot.id,
        ...querySnapshot.data(),
        nights
      })
    )
  } catch (error) {
    toast.error(`${error.message || texts.errorHappened}`)
  } finally {
    yield put(updateUser("isLoading", false))
  }
}

export default function* watcher() {
  yield takeLatest(USER_REQUESTED, getUsers)
}
