import { USERS_REQUESTED, UPDATE_USERS } from "./actionTypes"

export const updateUsers = (name, value) => ({
  type: UPDATE_USERS,
  payload: {
    name,
    value
  }
})

export const setFilters = filters => dispatch => {
  dispatch(updateUsers("filters", filters))
}

export const requestUsers = () => ({
  type: USERS_REQUESTED
})
