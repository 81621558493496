import sort from "fast-sort"
import { createSelector } from "reselect"

// export const getFilters = ({ users }) => users.filters

// export const getCollectionCursor = ({ users }) => users.collectionCursor

export const getSimpleUsersList = createSelector(
  ({ users }) => users.list,
  list => list.map(user => ({id: user.id, title: user.name}))
)

export const getUserList = ({ users }) => {
  const { list, filters } = users

  if (list.length === 0) return []

  const { order, search, page, rowsPerPage, sort: key } = filters
  let arr = [...list]

  if (search) {
    arr = arr.filter(
      item => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    )
  }
  // paginating
  arr = arr.slice((page + 1 - 1) * rowsPerPage, (page + 1) * rowsPerPage)

  // sorting
  arr = sort(arr).by({ [order]: u => u[key] })
  return [...arr]
}
