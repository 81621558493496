import React, { useState, useEffect } from 'react'
import moment from 'moment';
import Plot from 'react-plotly.js';

import styles from './styles.module.scss'

import { LEFT, RIGHT, BACK, BELLY, STRAIGHT_UP, VIBRATION, STRAIGHT_UP6, VIBRATION8 } from '../../constants/defaults';

const NightChart = ({ night }) => {

    const [chartData, setChartData] = useState(null)

    useEffect(() => {
        if(night) setChartData(getNightData(night))
    }, [night])


    const getNightData = (night) => {
        const times = []
        const labels = []
        const vibrationTimes = []
        const vibrationLabels = []

        let miliseconds = new Date(night.dateStart).getTime()
        const interval = night.checkInterval


        const arr = night.data.toUint8Array()

        arr.map((el, index) => {
            if(el === interval || el === 120) return null
            miliseconds += (interval * 1000) // seconds to miliseconds
            const date = moment(miliseconds)
            const str = date.format('YYYY-MM-DD HH:mm:ss')

            if(el === LEFT) {
                times.push(str)
                labels.push('Left')
                return null
            }
            if(el === RIGHT) {
                times.push(str)
                labels.push('Right')
                return null
            }
            if(el === BACK) {
                times.push(str)
                labels.push('Back')
                return null
            }
            if(el === BELLY) {
                times.push(str)
                labels.push('Belly')
                return null
            }
            if(el === STRAIGHT_UP) {
                times.push(str)
                labels.push('Straight-up')
                return null
            }
            if(el === STRAIGHT_UP6) {
                times.push(str)
                labels.push('Straight-up6')
                return null
            }
            if((el === VIBRATION || el === VIBRATION8)) {
                vibrationTimes.push(str)
                vibrationLabels.push('Right')
                return null
            }

            return null
        })

        return {
            times,
            labels,
            vibrationTimes,
            vibrationLabels
        }
    }

    if(!night) return null;

    return (
        <div className={styles.box}>
            <div>
                {
                    chartData && (
                        <Plot
                            data={[
                                {
                                    "marker": {"color": "lightblue"},
                                    "name": "no_vibration",
                                    "x": [...chartData.times],
                                    "y": [...chartData.labels],
                                    "type": "bar"
                                },
                                {
                                    "marker": {"color": "darkgreen"},
                                    "name": "vibration",
                                    "x": chartData.vibrationTimes,
                                    "y": chartData.vibrationLabels,
                                    "type": "bar"
                                }
                            ]}
                            layout={{
                                width: 800,
                                height: 450,
                                xaxis: {
                                  autorange: false,
                                  type: 'date',
                                  range: [chartData.times[0], chartData.times[chartData.times.length-1]]
                                },
                                yaxis: {
                                    fixedrange: true,
                                    categoryarray:['', 'Straight-up6', 'Belly', 'Straight-up', 'Left', 'Right', 'Back']
                                }
                            }}
                        />

                    )
                }
            </div>
        </div>
    )
}

export default NightChart
