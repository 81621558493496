import React from 'react'
import TextField from '@material-ui/core/TextField'
import styles from './styles.module.scss'

const Field = ({ label, input, meta: { touched, invalid, error }, ...custom}) => (
    <div className={styles.field}>
      <TextField
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        fullWidth
        {...input}
        {...custom}
      />
    </div>
  )

export default Field