import React from 'react'

import Row from '../Row'

const Rows = ({ rowsData, renderCell, headCells, enableCheckboxes, onRowPress, onRowSelect }) => {
  const handleRowPress = (row) => (
    () => {
      if (typeof onRowPress === 'function') {
        const data = { ...row }
        delete data.isSelected
        onRowPress(data)
      }
    }
  )

  const handleRowSelect = (id) => (
    () => {
      if (typeof onRowSelect === 'function') {
        onRowSelect(id)
      }
    }
  )

  return (
    <>
      {
        rowsData.allIds.map((id) => {
          const row = rowsData.byId[id]

          return (
            <Row
              key={row.id}
              headCells={headCells}
              rowData={row}
              isSelected={!!row.isSelected}
              enableCheckbox={enableCheckboxes}
              onRowClick={handleRowPress(row)}
              onCheckClick={handleRowSelect(row.id)}
              renderCell={renderCell}
            />
          )
        })
      }
    </>
  )
}

export default Rows
