import React from 'react'
import { Redirect } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import LockIcon from '@material-ui/icons/Lock'
import Typography from "@material-ui/core/Typography"
import { makeStyles } from '@material-ui/core/styles'
import LoginForm from '../../components/Forms/LoginForm'

import styles from './styles.module.scss'
import texts from '../../constants/texts'
import { routes } from '../../constants/routes'
import { loginUser } from '../../store/auth/actionCreators'

const useStyles = makeStyles(theme => ({
  avatar: {
    color: 'white',
    backgroundColor: theme.palette.primary.dark,
  },
}))

const Login = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated)

  if (isAuthenticated) return <Redirect to={routes.dashboard} />

  const onFormSubmit = ({email, password}) => {
    dispatch(loginUser(email, password))
  }

  return (
    <div className={styles.root}>
      <CssBaseline />
        <Paper className={styles.paper}>
            <div className={styles.avatarBox}>
                <Avatar className={classes.avatar}><LockIcon /></Avatar>
            </div>
            <div className={styles.avatarBox}>
              <Typography component="h1" variant="h6">
                {texts.signIn}
              </Typography>
            </div>
            <LoginForm onSubmit={onFormSubmit} />
        </Paper>
        <ToastContainer autoClose={2000} hideProgressBar position="bottom-right" />
    </div>
  )
}

export default Login
