import React from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'

const HeadRow = ({ cellList, onSelectAllPress, isIndeterminateChecked, isAllChecked, enableCheckbox = false, onSortPress, activeSortKey, order }) => {
  const onSelectAllChange = ({ target: { checked } }) => (
    onSelectAllPress(checked)
  )

  return (
    <TableHead>
      <TableRow>
        {enableCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={isIndeterminateChecked}
              checked={isAllChecked}
              onChange={onSelectAllChange}
            />
          </TableCell>
        )}
        {cellList.map(({ name, label, disableSort, sortingKey }, idx) => {
          return (
            <TableCell key={idx}>
              {
                !disableSort && (
                  <TableSortLabel
                    active={(sortingKey || name) === activeSortKey}
                    direction={order}
                    onClick={() => onSortPress(sortingKey || name)}
                  >
                    {label}
                  </TableSortLabel>
                )
              }
              {
                disableSort && (<>{label}</>)
              }
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default HeadRow
