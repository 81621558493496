import React from 'react'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

const BaseSelect = ({list, label, defaultValue, onChange}) => (
  <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select
          style={{minWidth: 100}}
          defaultValue={defaultValue || ''}
          onChange={onChange}
      >
        {
          list.map(item => (
            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
          ))
        }
    </Select>
  </FormControl>
)

export default BaseSelect
