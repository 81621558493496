import moment from 'moment'
import { positionsZeroValues } from '../constants/defaults'
import { minutesToHours } from './time'
import { geSideValues, getPositionsPercentage } from './importedNights'

const getYearGraphData = (year, nights) => {
    const date = moment().year(year)
    const startYearDay = date.clone().startOf('year')
    const entYearDay = date.clone().endOf('year')
    const weeksInYear = date.weeksInYear()

    const initData = Array.from(Array(weeksInYear), (_, i) => i+1).reduce((res, val) => {
        res[val] = { name: val, ...positionsZeroValues}
        return res
    }, {})


    const yearWeeksNights = nights.reduce((res, night) => {
        const dateStart = moment(night.dateStart)
        if(dateStart.isBetween(startYearDay, entYearDay, 'days', [])) {
            const day = dateStart.format('DDD')
            const week = dateStart.format('w')
            const sidesArr = night.data.toUint8Array()

            const sideTotals = geSideValues(sidesArr, night.checkInterval)
            sideTotals.duration = night.duration

            res[week] = {...res[week], [day]: sideTotals} // collect all nights per week
        }
        return res
    }, {})

    Object.keys(yearWeeksNights).map(week => {
        const weekTotals = Object.values(yearWeeksNights[week]).reduce((res, {left, back, belly, right, straightUp, straightUp6, duration}) => {
            res.left += left.hours
            res.leftPos += left.positionTotal
            res.right += right.hours
            res.rightPos += right.positionTotal
            res.back += back.hours
            res.backPos += back.positionTotal
            res.belly += belly.hours
            res.bellyPos += belly.positionTotal
            res.straightUp += straightUp.hours
            res.straightUpPos += straightUp.positionTotal
            res.straightUp6 += straightUp6.hours
            res.straightUp6Pos += straightUp6.positionTotal
            res.duration += duration
            return res
        }, {...positionsZeroValues, leftPos:0, rightPos: 0, backPos: 0, bellyPos: 0, straightUpPos: 0, straightUp6Pos: 0, duration: 0})

        weekTotals.time = minutesToHours(weekTotals.duration)

        const percentage = getPositionsPercentage({
            left: weekTotals.leftPos,
            right: weekTotals.rightPos,
            back: weekTotals.backPos,
            belly: weekTotals.bellyPos,
            straightUp: weekTotals.straightUpPos,
            straightUp6: weekTotals.straightUp6Pos,
        })

        weekTotals.leftPerc = percentage.left
        weekTotals.rightPerc = percentage.right
        weekTotals.backPerc = percentage.back
        weekTotals.bellyPerc = percentage.belly
        weekTotals.straightUpPerc = percentage.straightUp
        weekTotals.straightUp6Perc = percentage.straightUp

        initData[week] = {...initData[week], ...weekTotals}

        return null
    })

    return initData
}

export default getYearGraphData
