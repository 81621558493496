import { createSelector } from "reselect"
import moment from "moment"

export const getNightsData = createSelector(
  ({ user }) => user.nights,
  nights => {
    const data = {}

    nights.map(nigth => {
      if(!nigth.dateStart) return null

      const date = moment(nigth.dateStart)
      const year = date.year()
      const month = date.month()
      const week = date.week()
      const firstWeekDate = date.clone().startOf('isoWeek')
      const endWeekDate = date.clone().endOf('isoWeek')

      data[year] = data[year] || {}
      data[year].weeks = {
        ...data[year].weeks,
        [week]: {
          value: week,
          label: `${week}: ${firstWeekDate.format('DD/MM')} - ${endWeekDate.format('DD/MM')}`
        }
      }
      data[year].months = {
        ...data[year].months,
        [month]: {
          value: month,
          label: date.format('MMMM')
        }
      }
    })

    return {
      years: data,
    }
  }
)
